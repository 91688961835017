import * as React from 'react';
import {PaletteMode} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Features from './components/Features';
import HomeArrow from './components/HomeArrow';
import BookDemo from './components/BookDemo';
import Footer from './components/Footer';
import getLPTheme from './getLPTheme';
import GraphDemo from "./components/GraphDemo";
import AboutUs from "./components/AboutUs";
import Container from "@mui/material/Container";
import TypingAnimation from "./components/TypingAnimation";

interface ToggleCustomThemeProps {
    showCustomTheme: Boolean;
    toggleCustomTheme: () => void;
}

function ToggleCustomTheme({
                               showCustomTheme,
                               toggleCustomTheme,
                           }: ToggleCustomThemeProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100dvw',
                position: 'fixed',
                bottom: 24,
            }}
        >
        </Box>
    );
}

export default function App() {
    const [mode, setMode] = React.useState<PaletteMode>('dark');
    const [showCustomTheme, setShowCustomTheme] = React.useState(false);
    const LPtheme = createTheme(getLPTheme(mode));
    const defaultTheme = createTheme({palette: {mode}});
    const [demoStarted, setDemoStarted] = React.useState<Boolean>(false);
    const [aboutSectionExpanded, setAboutSectionExpanded] = React.useState<Boolean>(false);



    const toggleColorMode = () => {
        setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
    };

    const toggleCustomTheme = () => {
        setShowCustomTheme((prev) => !prev);
    };

    return (
        <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
            <CssBaseline/>
            <AppAppBar mode={mode} toggleColorMode={toggleColorMode} setDemoStarted={setDemoStarted} />
            <Hero/>
            <AboutUs aboutSectionExpanded={aboutSectionExpanded} setAboutSectionExpanded={setAboutSectionExpanded} />
            <BookDemo/>
            <HomeArrow aboutSectionExpanded={aboutSectionExpanded} setDemoStarted={setDemoStarted} />

            <Container sx={{
                height: {
                    xs: '50px',
                    md: '100px'
                }
            }}></Container>
            <TypingAnimation demoStarted={demoStarted}/>
            <GraphDemo demoStarted = {demoStarted} />
            <Box sx={{bgcolor: 'background.default', width: '100%'}}>
                <Features/>
                <Divider/>
                <Footer/>
            </Box>
        </ThemeProvider>
    );
}
