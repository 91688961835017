import * as React from 'react';
import {TypeAnimation} from 'react-type-animation';
import {alpha} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

const story = [
    'You\'re about to witness the power of OrgAtlas...',
    1000,
    'Let\'s set the scene...',
    1000,
    'HQ: A daily check in meeting is about to begin.',
    1000,
    'Jessica Harris: Good morning, everyone. Let\'s dive right into our project updates. Daniel, how\'s the platform development progressing?\n',
    1000,
    'Daniel Lee: We’ve just completed the final testing phase. The analytics feature is performing even better than we anticipated.\n',
    1000,
    'Jessica Harris: That\'s fantastic news. Emma, what’s the marketing strategy looking like?\n',
    1000,
    'Emma Clark: We’ve got a multi-channel campaign ready to roll out. Social media teasers, blog posts, and a webinar series to generate buzz and educate potential customers.\n',
    1000,
    'Jessica Harris: Perfect. Raj, how are we doing on the sales front?\n',
    1000,
    'Raj Patel: We’ve identified several key accounts that are very interested. I’ve scheduled demos for next week. The client feedback so far has been very positive.\n',
    1000,
    'Jessica Harris: Great to hear. Sarah, what’s the plan for customer support once we launch?\n',
    1000,
    'Sarah Jones: We’re setting up a dedicated support team specifically for this platform. We’ll have comprehensive training sessions and detailed documentation ready for our users.\n',
    1000,
    'Jessica Harris: Excellent. And finally, Olivia, how\'s the employee wellness program going?\n',
    1000,
    'Olivia Martinez: We’ve rolled out the first phase. Employees are already engaging with the new wellness resources, and feedback has been overwhelmingly positive.\n',
    1000,
    'Jessica Harris: That’s wonderful. It’s crucial for us to support our team, especially with such an ambitious project on our hands.\n',
    1000,
    'Jessica Harris: Alright, let’s keep up the great work, everyone. We’re on track to make a significant impact with this launch.\n',
    1000,
    'The End.\n',
    1000,
    'OrgAtlas has processed this transcript. You can explore this scene interactively to see the insights.\n'
];

interface Props {
    demoStarted: Boolean
}

export default function TypingAnimation(props: Props) {


    return (
        <div id={'demonstration'}>
            <Container
                sx={{
                    display: props.demoStarted ? 'flex' : 'none',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: {xs: 14, sm: 20},
                    pb: {xs: 4, sm: 6},
                }}
            >
                <Box
                    sx={(theme) => ({
                        mt: {xs: 2, sm: 4},
                        p: {xs: 2, sm: 4},
                        alignSelf: 'center',
                        // height: {xs: 300, sm: 300},
                        width: '100%',
                        backgroundImage:
                            theme.palette.mode === 'light'
                                ? 'url("/static/images/templates/templates-images/hero-light.png")'
                                : 'url("/static/images/templates/templates-images/hero-dark.png")',
                        backgroundSize: 'cover',
                        borderRadius: '10px',
                        outline: '1px solid',
                        outlineColor:
                            theme.palette.mode === 'light'
                                ? alpha('#BFCCD9', 0.5)
                                : alpha('#9CCCFC', 0.1),
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                        overflow: 'hidden' // Ensure the content does not overflow
                    })}
                >
                    <TypeAnimation
                        sequence={story}
                        speed={60}
                        wrapper="span"
                        cursor={true}
                        repeat={0}
                        style={{fontSize: '1.5em', display: 'inline-block'}}
                        deletionSpeed={95}
                    />
                </Box>
            </Container>
        </div>
    );
}
