import * as React from 'react';
import * as d3 from 'd3';
import GraphPopup from './GraphPopup';
import GraphKey from './GraphKey';
import {useEffect, useRef, useState} from "react";
import {ScaleOrdinal} from "d3-scale";
import {color} from "d3";

interface Node {
    id: string;
    group: string;
    x?: number;
    y?: number;
    vx?: number;
    vy?: number;
    fx?: number | null;
    fy?: number | null;
}

interface Link {
    source: string | Node;
    target: string | Node;
}

interface Props {
    data: string,
    demoStarted:Boolean
}

function Graph(props: Props) {
    const svgRef = useRef<SVGSVGElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [dimensions, setDimensions] = useState({width: 0, height: 0});
    const [selectedNode, setSelectedNode] = useState<Node | null>(null);
    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [nodes, setNodes] = useState<Node[]>([]);
    const [links, setLinks] = useState<Link[]>([]);
    var colorScale = d3.scaleOrdinal(d3.schemeCategory10).domain(['Person', 'Skill', 'Project']).range(['#BB4430','#7EBDC2','#F3DFA2']);

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                const {clientWidth, clientHeight} = containerRef.current;
                setDimensions({width: clientWidth, height: clientHeight});
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [props.demoStarted]);

    useEffect(() => {
        console.log('loading json data')
        fetch(props.data)
            .then(response => response.json())
            .then(data => {
                setNodes(data.nodes);
                console.log(nodes)
                setLinks(data.links);
            })
            .catch(error => {
                console.error('Error fetching the JSON data:', error);
            });
    }, [props.data, props.demoStarted]);

    useEffect(() => {
        console.log('rendering')
        if (dimensions.width > 0 && dimensions.height > 0 && nodes.length > 0 && links.length > 0) {
            console.log('drawing chart')
            drawChart();
        }
    }, [dimensions, nodes, links]);

    const drawChart = () => {
        console.log('drawing chart')

        const svg = d3.select(svgRef.current);
        svg.selectAll('*').remove();

        const simulation = d3.forceSimulation<Node>(nodes)
            .force('link', d3.forceLink<Node, Link>(links).id(d => d.id).distance(50))
            .force('charge', d3.forceManyBody().distanceMax(Math.min(dimensions.height/4, dimensions.width/4)).strength(-200))
            // .force("collide", d3.forceCollide(30))
            .force('center', d3.forceCenter(dimensions.width / 2, dimensions.height / 2));

        const link = svg.append('g')
            .attr('class', 'links')
            .selectAll('line')
            .data(links)
            .enter()
            .append('line')
            .attr('stroke', '#999')
            .attr('stroke-width', 1.5);

        const node = svg.append('g')
            .attr('class', 'nodes')
            .selectAll('circle')
            .data(nodes)
            .enter()
            .append('circle')
            .attr('r', 20)
            .attr('fill', d => colorScale(d.group.toString()))
            .call(d3.drag<SVGCircleElement, Node>()
                .on('start', (event, d) => dragStarted(event, d, simulation))
                .on('drag', (event, d) => dragged(event, d, dimensions.width, dimensions.height))
                .on('end', (event, d) => dragEnded(event, d, simulation)))
            .on('click', (event, d) => handleNodeClick(event, d));


        simulation.on('tick', () => {
            link
                .attr('x1', d => (d.source as Node).x!)
                .attr('y1', d => (d.source as Node).y!)
                .attr('x2', d => (d.target as Node).x!)
                .attr('y2', d => (d.target as Node).y!);

            node
                .attr('cx', d => d.x!)
                .attr('cy', d => d.y!);
        });


        // Fix NaN values issue
        nodes.forEach(node => {
            node.x = node.x ?? Math.random() * dimensions.width;
            node.y = node.y ?? Math.random() * dimensions.height;
        });

        simulation.nodes(nodes);
        simulation.force<d3.ForceLink<Node, Link>>('link')!.links(links);
        simulation.alpha(1).restart();
    };

    const dragStarted = (event: d3.D3DragEvent<SVGCircleElement, Node, Node>, d: Node, simulation: d3.Simulation<Node, undefined>) => {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
    };

    const dragged = (event: d3.D3DragEvent<SVGCircleElement, Node, Node>, d: Node, width: number, height: number) => {
        d.fx = Math.max(20, Math.min(width - 20, event.x));
        d.fy = Math.max(20, Math.min(height - 20, event.y));
    };

    const dragEnded = (event: d3.D3DragEvent<SVGCircleElement, Node, Node>, d: Node, simulation: d3.Simulation<Node, undefined>) => {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
    };

    const handleNodeClick = (event: any, d: Node) => {
        setSelectedNode(d);
        setPopupVisible(true);
    };

    return (
        <div id={'graph'} ref={containerRef} style={{width: '100%', height: '100%', position: 'relative'}}>
            <svg ref={svgRef} width={dimensions.width} height={dimensions.height}></svg>
            {selectedNode && (
                <GraphPopup title={selectedNode.id} content={selectedNode.group} visible={popupVisible}
                            setVisibility={setPopupVisible}/>
            )}
            <GraphKey colors={[{color: colorScale('Person'), label: 'Person'}, {color: colorScale('Skill'), label: 'Skill'}, {color: colorScale('Project'), label: 'Project'}]}/>
        </div>
    );
}

export default Graph;