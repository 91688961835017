import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Container from "@mui/material/Container";
import * as React from "react";

interface Props {
    aboutSectionExpanded: Boolean
    setDemoStarted: Function
}

export default function HomeArrow(props: Props) {

    const scrollToSection = (sectionId: string) => {
        props.setDemoStarted(true);
        const sectionElement = document.getElementById(sectionId);

        const offset = -90;
        if (sectionElement) {
            const targetScroll = sectionElement.offsetTop - offset;
            sectionElement.scrollIntoView({behavior: 'smooth'});
            window.scrollTo({
                top: targetScroll,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div onClick={() => scrollToSection('demonstration')}>
            {!props.aboutSectionExpanded && (<Container
                sx={{
                    position: 'absolute',
                    left: '50%',
                    bottom: {
                        xs: '5px',
                        md: '50px'
                    },
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                }}
            >
                <Container sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Container sx={{
                        display: {xs: 'none', md: 'flex'},
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <a style={{color: '#90caf9', textDecoration: 'none'}}>
                            <h3>See How It Works</h3>
                        </a>
                    </Container>
                    <KeyboardDoubleArrowDownIcon fontSize='large'/>
                </Container>
            </Container>)}
        </div>

    )
}
