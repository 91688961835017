import Button from "@mui/material/Button";
import * as React from "react";
import Container from "@mui/material/Container";

export default function BookDemo() {
    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                pt: {xs: 3, sm: 3},
                pb: {xs: 2, sm: 2},
            }}
        >
            <Button variant="outlined" color="primary">
                <a href={"https://calendly.com/daniel-orgatlas/30min"}
                   style={{color: '#90caf9', textDecoration: 'none'}}>
                    <h3>Book a Demo</h3>
                </a>
            </Button>
        </Container>
    )
}



