import React, {Component} from 'react';
import {alpha} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";


interface Props {
    aboutSectionExpanded: Boolean
    setAboutSectionExpanded: Function
}

export default function AboutUs(props:Props){

    return (
        <Container
            id="about-us"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box
                sx={(theme) => ({
                    p: {xs: 2, sm: 4},
                    alignSelf: 'center',
                    width: '100%',
                    backgroundImage:
                        theme.palette.mode === 'light'
                            ? 'url("/static/images/templates/templates-images/hero-light.png")'
                            : 'url("/static/images/templates/templates-images/hero-dark.png")',
                    backgroundSize: 'cover',
                    borderRadius: '10px',
                    outline: '1px solid',
                    outlineColor:
                        theme.palette.mode === 'light'
                            ? alpha('#BFCCD9', 0.5)
                            : alpha('#9CCCFC', 0.1),
                    boxShadow:
                        theme.palette.mode === 'light'
                            ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                            : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                    overflow: 'hidden' // Ensure the content does not overflow
                })}
            >
                <div className="about-us-container">
                    <h1>Welcome to OrgAtlas</h1>
                    <h2>Mission</h2>
                    <p>
                        The OrgAtlas mission is to make knowledge faster and easier to access
                    </p>
                    {props.aboutSectionExpanded && (
                        <>
                            <h2>Theory</h2>
                            <p>
                                OrgAtlas was inspired by the theory of transactive memory and group mind.
                                Transactive memory is the concept that our interactions within the workplace lead to
                                a personal understanding of who knows what, and who is responsible for tasks and
                                functions.
                                Group mind, therefore, is the combined understanding and summation of these
                                individual understandings, derived from the inner interactions.
                                OrgAtlas aims to bridge the gap between the individual and the organisational group
                                mind.
                            </p>
                            <h2>Solutions</h2>
                            <p>
                                In practice, the tool processes digital interactions and documentation to
                                distinguish key features of the organisational environment, such as people, skills
                                and projects.
                                This can be configured to meet the specific nuances of the industry.
                                The tool builds an understanding of the interactions taking place and maps them in a
                                network that can be searched, described and explored.
                                This is referred to as the ‘Atlas’, a map of the various interactions and knowledge
                                that can be explored by the individual.

                            </p>
                            <h2>Solutions</h2>
                            <h3>For Decision Makers</h3>
                            <p>
                                <ul>
                                    <li>Resource allocation</li>
                                    <li>Skill identification</li>
                                    <li>Employee load management</li>
                                    <li>Risk mitigation via skill forecasting</li>
                                    <li>High value employee identification</li>
                                </ul>
                            </p>

                            <h3>For Employees</h3>
                            <p>
                                <ul>
                                    <li>Expert identification/location</li>
                                    <li>Knowledge retrieval</li>
                                    <li>Reduce effort duplication</li>
                                </ul>
                            </p>

                            <h3>For Human Resources</h3>
                            <p>
                                <ul>
                                    <li>Talent acquisition</li>
                                    <li>Talent development</li>
                                    <li>Opportunity identification</li>
                                    <li>High performer identification</li>
                                </ul>
                            </p>

                            <h2>Vision</h2>
                            <p>
                                Looking to the future, OrgAtlas is committed to working closely with our clients to
                                tailor our solutions to their specific needs, providing a professional and personal
                                touch that ensures success. <a href={"https://calendly.com/daniel-orgatlas/30min"}
                                                               style={{color: '#90caf9', textDecoration: 'none'}}>Book
                                a demo today</a>, to see how OrgAtlas can deliver value to your organisation.
                            </p>
                        </>
                    )}
                    <Button onClick={()=>props.setAboutSectionExpanded(!props.aboutSectionExpanded)} variant="outlined" color="primary">
                        {props.aboutSectionExpanded ? 'Show Less' : 'Continue Reading'}
                    </Button>
                </div>
            </Box>
        </Container>
    );
    }